import React, { useEffect } from 'react';
import Header from './component/header/Header';
import Footer from './component/footer/Footer';
import styles from './App.module.css';
import { session } from './space';
import { useSession } from '@mtribes/client-react';
import Routes from './Routes';

const KNOWN_USER_ID = 'guy';
const STORAGE_KEY_USER_ID = 'uid';

const App: React.FC = () => {
	useSession(session, { on: 'ready' });

	function updateSession(userId?: string) {
		if (userId) {
			localStorage.setItem(STORAGE_KEY_USER_ID, userId);
		} else {
			localStorage.removeItem(STORAGE_KEY_USER_ID);
		}
		const campaign = new URLSearchParams(window.location.search).get('c') || undefined;
		session.start({ userId, fields: { campaign } });
	}

	useEffect(() => {
		// use local storage to save login status
		const userId = localStorage.getItem(STORAGE_KEY_USER_ID) || undefined;
		updateSession(userId);
	}, []);

	if (session.status !== 'primed') return null;

	return (
		<div className={styles.root}>
			<Header
				loggedIn={!session.anonymous}
				onSignIn={() => updateSession(KNOWN_USER_ID)}
				onSignUp={() => updateSession(`test-user-${Date.now()}`)}
				onSignOut={() => updateSession()}
			/>
			<main className={styles.main}>
				<Routes />
			</main>
			<Footer />
		</div>
	);
};

export default App;
