function getListCacheId(listId: string): string {
	return `list:${listId}`;
}

export function getCachedList(listId: string): api.List | undefined {
	if (!listId) return;
	const entry = localStorage.getItem(getListCacheId(listId));
	if (!entry) return;
	const list = JSON.parse(entry);
	if (!list.items || !list.items.length) return;
	return list;
}

export function setCachedList(listId: string, list: api.List) {
	if (!list || !list.items || !list.items.length) return;
	return localStorage.setItem(getListCacheId(listId), JSON.stringify(list));
}
