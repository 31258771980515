import { useState, useEffect } from 'react';

/* Same as values defined in styles */
const HEADER_HEIGHT = 60;
const TILE_OFFSET = 68;
const BOTTOM_MARGIN = 24;

export default function useWide() {
	const [isWide, setIsWide] = useState(getWide());
	useEffect(() => {
		function onResize() {
			// If the hero height exceeds the window height then switch to the wide layout
			const newWide = getWide();
			if (isWide !== newWide) setIsWide(newWide);
		}
		window.addEventListener('resize', onResize);
		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, [isWide]);
	return isWide;
}

function getWide() {
	if (typeof window === 'undefined') return false;
	const targetHeight = window.innerWidth * (9 / 16) + TILE_OFFSET;
	return targetHeight > window.innerHeight - BOTTOM_MARGIN - HEADER_HEIGHT;
}
