// Code generated by mtribes. DO NOT EDIT.

/* tslint:disable */
import {
	Client,
	Broker,
	Signal as SignalAlias,
	SessionEvent as SessionEventAlias,
	ChangeEvent as ChangeEventAlias,
	ColorProperty as ColorPropertyAlias,
} from '@mtribes/client-browser';
import { fallbacks } from './fallbacks';
import Hcd1Collection from './collection/hcd1Collection';
import Hcd2Collection from './collection/hcd2Collection';
import Hcd3Collection from './collection/hcd3Collection';
import Hcd4Collection from './collection/hcd4Collection';
import Hsd1Collection from './collection/hsd1Collection';
import Hsd2Collection from './collection/hsd2Collection';
import Hsd3Collection from './collection/hsd3Collection';
import Hsd4Collection from './collection/hsd4Collection';
import PersonalizationCollection from './collection/personalizationCollection';

export { Client, Session, SessionStatus, ChangeEventType, Experience, Section } from '@mtribes/client-browser';

export type Signal<SessionEvent> = SignalAlias<SessionEvent>;
export type SessionEvent = SessionEventAlias;
export type ChangeEvent = ChangeEventAlias;
export type ColorProperty = ColorPropertyAlias;

const broker = new Broker();
export const client = new Client('cadc1668d6d24991a34dba19e4bec281', fallbacks, broker);
export const session = client.session;

export const collections: Collections = {
	personalization: new PersonalizationCollection('2x6vwxg', ['gZpmdx8', 'aZKEEr7', '2x3BkZ0'], broker),
	hsd1: new Hsd1Collection('GgZpdZ8', ['qaZKEr7', 'M2x3kx0', 'g3ZaDLk'], broker),
	hsd2: new Hsd2Collection('9XrmWZz', ['zQLegxw', 'A3Zygrl', '3RZdPZm'], broker),
	hsd3: new Hsd3Collection('ypLj8xD', ['MlZz5L4', 'yGLOyxb', 'E6Z1qZ5'], broker),
	hsd4: new Hsd4Collection('90x8JLG', ['p9xwBZz', '8VZljrn', 'aOrojxP'], broker),
	hcd3: new Hcd3Collection('2OLQ0r0', ['NnrWlL3', 'qJr2dZd', 'Q9LYALK', 'JQxD1rl'], broker),
	hcd2: new Hcd2Collection('1vLvjLO', ['Jwx7Drz', '2jrgRxW', 'J5rAbL7', 'EWZ0Ax8'], broker),
	hcd4: new Hcd4Collection('VdLPlLb', ['dkLGWL4', 'KOZkvL2', 'v6Z5vx9', 'jYZB8rq'], broker),
	hcd1: new Hcd1Collection('wprNkL2', ['4Qx4kZg', 'mqZnprK', 'GXx9YxN', 'MYLXeZV'], broker),
};

export interface Collections {
	personalization: PersonalizationCollection;
	hsd1: Hsd1Collection;
	hsd2: Hsd2Collection;
	hsd3: Hsd3Collection;
	hsd4: Hsd4Collection;
	hcd3: Hcd3Collection;
	hcd2: Hcd2Collection;
	hcd4: Hcd4Collection;
	hcd1: Hcd1Collection;
}
