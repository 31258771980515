import React, { useState } from 'react';
import styles from './Hero.module.css';
import ArrowButton from './ArrowButton';
import HeroTitle from './HeroTitle';
import HeroTiles from './HeroTiles';
import cx from 'classnames';
import useList from '../../../hook/useList';
import Picture from '../../Picture';
import useWide from './useWide';

const IMAGE_SIZES = [{ width: 480 }, { width: 720 }, { width: 1440 }, { width: 1920 }];

interface Props {
	className?: string;
	listId: string;
}

const Hero: React.FC<Props> = ({ className, listId }) => {
	const list = useList(listId) || { id: listId, items: [] };
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [lastIndex, setLastIndex] = useState(0);
	const wide = useWide();

	function updateSelectedIndex(index: number) {
		const maxIndex = list.items.length - 1;
		if (index < 0) index = maxIndex;
		else if (index > maxIndex) index = 0;
		setLastIndex(selectedIndex);
		setSelectedIndex(index);
	}

	const selectedItem = list.items[selectedIndex];
	const lastItem = list.items[lastIndex];
	return (
		<article className={cx(styles.root, { [styles.wide]: wide }, className)}>
			{lastItem && lastItem !== selectedItem && (
				<Picture
					key={lastItem.id}
					alt="hero"
					className={cx(styles.image, styles.fadeOut)}
					source={lastItem.images.wallpaper}
					sizes={IMAGE_SIZES}
				/>
			)}
			{selectedItem && (
				<Picture
					key={selectedItem.id}
					alt="hero"
					className={cx(styles.image, { [styles.fadeIn]: lastItem })}
					source={selectedItem.images.wallpaper}
					sizes={IMAGE_SIZES}
				/>
			)}
			<div className={cx(styles.overlay, styles.image)} />
			{!!list.items.length && (
				<>
					<div className={styles.info}>
						<ArrowButton className={styles.arrow} onClick={() => updateSelectedIndex(selectedIndex - 1)} />
						<HeroTitle item={selectedItem} />
						<ArrowButton
							className={styles.arrow}
							direction="right"
							onClick={() => updateSelectedIndex(selectedIndex + 1)}
						/>
					</div>
					<HeroTiles items={list.items} selectedIndex={selectedIndex} onSelect={updateSelectedIndex} />
				</>
			)}
		</article>
	);
};

export default Hero;
