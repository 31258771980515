const BASE_URL = `https://eu.deltatreaxis.com/api`;

export async function getList(listId?: string): Promise<api.List | undefined> {
	if (!listId) return undefined;
	const list = await fetch(`${BASE_URL}/lists/${listId}`).then((res) => {
		if (!res.ok) throw res;
		return res.json();
	});
	return list;
}
