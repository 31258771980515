import React from 'react';
import styles from './Footer.module.css';
import FooterLink from './FooterLink';
import { ReactComponent as FacebookIcon } from './facebook.svg';
import { ReactComponent as InstagramIcon } from './instagram.svg';
import { ReactComponent as YoutubeIcon } from './youtube.svg';
import Link from '../Link';

const Footer: React.FC = () => {
	return (
		<footer className={styles.root}>
			<div className={styles.socialLinks}>
				<Link className={styles.socialLink}>
					<FacebookIcon />
				</Link>
				<Link className={styles.socialLink}>
					<InstagramIcon />
				</Link>
				<Link className={styles.socialLink}>
					<YoutubeIcon />
				</Link>
			</div>
			<div className={styles.links}>
				<FooterLink>Audio and subtitles</FooterLink>
				<FooterLink>Help Centre</FooterLink>
				<FooterLink>Media Centre</FooterLink>
				<FooterLink>jobs</FooterLink>
				<FooterLink>private</FooterLink>
				<FooterLink>Cookie Preferences</FooterLink>
				<FooterLink>Contact us</FooterLink>
			</div>
			<div className={styles.links}>
				<FooterLink>Audio Description</FooterLink>
				<FooterLink>Gift cards</FooterLink>
				<FooterLink>Investor Relations</FooterLink>
				<FooterLink>Terms of Use</FooterLink>
				<FooterLink>Legal Notices</FooterLink>
				<FooterLink>Corporate Information</FooterLink>
				<FooterLink>Service Code</FooterLink>
			</div>
		</footer>
	);
};

export default Footer;
