import * as development from './mtspace/demo_app_dev';
import * as production from './mtspace/demo_app';

const w: any = typeof window !== 'undefined' ? window : {};

let { REACT_APP_ENVIRONMENT, REACT_APP_SERVICE_URL } = w['GLOBAL'] || {};
if (!REACT_APP_ENVIRONMENT) {
	REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'production';
}
if (!REACT_APP_SERVICE_URL) {
	REACT_APP_SERVICE_URL = process.env.REACT_APP_SERVICE_URL || 'https://client.mtribes.com';
}

const environments: any = { development, production };
const { session, client, collections } = environments[REACT_APP_ENVIRONMENT as string];

client.serviceUrl = REACT_APP_SERVICE_URL;
client.waitForMsec = 4000;
client.sessionLock = false;

export { session, collections };
