import React from 'react';
import styles from './HeroTitle.module.css';

interface Props {
	item?: api.Item;
}

const HeroTitle: React.FC<Props> = ({ item }) => {
	if (!item) return null;
	return (
		<div className={styles.root}>
			{item.badge && <p className={styles.badge}>{item.badge}</p>}
			<h2 className={styles.title}>{item.title}</h2>
			<p className={styles.description}>{item.shortDescription}</p>
		</div>
	);
};

export default HeroTitle;
