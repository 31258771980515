import React from 'react';
import { useSection, useExperience } from '@mtribes/client-react';
import Rows from '../component/row/Rows';

interface Props {
	collection: any;
}

const HomeComplex: React.FC<Props> = ({ collection }) => {
	const banner = useExperience(collection.directmessage);
	const hero = useExperience(collection.hero);
	const { children } = useSection(collection.section);
	return <Rows experiences={[banner, hero, ...children]} />;
};

export default HomeComplex;
