import React from 'react';
import styles from './Carousel.module.css';
import cx from 'classnames';

interface Props {
	className?: string;
}

const Carousel: React.FC<Props> = ({ className, children }) => {
	return <div className={cx(styles.root, className)}>{children}</div>;
};

export default Carousel;
