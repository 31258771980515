// Code generated by mtribes. DO NOT EDIT.

/* tslint:disable */
import { Section, Broker } from '@mtribes/client-browser';

import RecsExperience from '../experience/recsExperience';

export class PersonalizationSectionSupported {
	readonly Recs = 'RecsExperience';
}

export type PersonalizationSectionSupportedTypes = RecsExperience;

export default class PersonalizationSection extends Section<
	PersonalizationSectionSupported,
	PersonalizationSectionSupportedTypes
> {
	// Type alias's for easy switching of supported Section Experience types
	readonly supported = new PersonalizationSectionSupported();

	constructor(id: string, parentId: string, broker: Broker) {
		super(
			id,
			parentId,
			broker,
			{
				id: 'KkP2WOw',
				t: 's',
			},
			{
				'86pYJ0R': RecsExperience,
			}
		);
	}
}
