import React from 'react';
import { collections } from './space';
import HomeSimple from './page/HomeSimple';
import HomeComplex from './page/HomeComplex';
import NotFound from './page/NotFound';

const Routes: React.FC = () => {
	const collectionName = window.location.pathname.split('/')[1] || 'hsd1';
	const collection = (collections as any)[collectionName];

	if (!collection) return <NotFound />;
	if (collection.hasOwnProperty('section')) return <HomeComplex collection={collection} />;
	return <HomeSimple collection={collection} />;
};

export default Routes;
