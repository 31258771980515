// Code generated by mtribes. DO NOT EDIT.

/* tslint:disable */
import { Experience, Broker } from '@mtribes/client-browser';

export interface BannerExperienceData {
	title?: string;
	backgroundImage?: string;
	customImageUrl?: string;
	message?: string;
	action?: string;
	actionLocation?: string;
	dismissable?: boolean;
	theme?: string;
}

export default class BannerExperience extends Experience<BannerExperienceData> {
	/**
	 * The kind of Experience in string form.
	 */
	readonly kind = 'BannerExperience';

	constructor(id: string, parentId: string, broker: Broker) {
		super(id, parentId, broker, {
			id: 'xo08EoO',
			t: 'e',
			dataAlias: {
				'1': 'title',
				'7': 'backgroundImage',
				'8': 'customImageUrl',
				'2': 'message',
				'3': 'action',
				'4': 'actionLocation',
				'5': 'dismissable',
				'6': 'theme',
			},
		});
	}
}
