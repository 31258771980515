/* Should match widths defined in `variables.css` */
export const IMAGE_WIDTH: any = {
	poster: 194,
	tile: 410
};

export function buildSources(url: string, sizes: image.Size[]): image.Source[] {
	return [...sizes].sort(compareSizes).map(({ width, height }, i) => ({
		url: setImageSize(url, width, height),
		media: i === sizes.length - 1 ? `(min-width: ${sizes[i - 1].width}px)` : `(max-width: ${width - 1}px)`
	}));
}

function compareSizes(a: image.Size, b: image.Size): number {
	return a.width - b.width;
}

export function setImageSize(url: string, width: number, height?: number) {
	if (!url) return '';
	const [base, args] = url.split('?');
	const params = args.split('&').reduce<any>((acc, arg: string) => {
		const [k, v] = arg.split('=');
		acc[k] = v;
		return acc;
	}, {});
	params.Width = width;
	if (height === undefined) delete params.Height;
	else params.Height = height;
	return `${base}?${Object.entries(params)
		.map(([k, v]) => `${k}=${v}`)
		.join('&')}`;
}
