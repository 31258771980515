// Code generated by mtribes. DO NOT EDIT.

/* tslint:disable */
import { Experience, Broker } from '@mtribes/client-browser';

export interface MediaRowExperienceData {
	contentId?: string;
	autoplayTrailer?: boolean;
}

export default class MediaRowExperience extends Experience<MediaRowExperienceData> {
	/**
	 * The kind of Experience in string form.
	 */
	readonly kind = 'MediaRowExperience';

	constructor(id: string, parentId: string, broker: Broker) {
		super(id, parentId, broker, {
			id: 'DX6Gg80',
			t: 'e',
			dataAlias: {
				'1': 'contentId',
				'2': 'autoplayTrailer',
			},
		});
	}
}
