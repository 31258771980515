// Code generated by mtribes. DO NOT EDIT.

/* tslint:disable */
import { Collection, Broker } from '@mtribes/client-browser';

import BannerExperience from '../experience/bannerExperience';
import HeroExperience from '../experience/heroExperience';
import Hcd1Section from '../section/hcd1Section';
import ThemeExperience from '../experience/themeExperience';

export default class Hcd1Collection extends Collection {
	
	readonly directmessage: BannerExperience;
	readonly hero: HeroExperience;
	readonly section: Hcd1Section;
	readonly theme: ThemeExperience;

	constructor(id: string, childIds: string[], broker: Broker) {
		super(id, broker, {
			id: 'zqy9Bqe',
			t: 'c'
		});
		this.directmessage = new BannerExperience(childIds[0], id, broker);
		this.hero = new HeroExperience(childIds[1], id, broker);
		this.section = new Hcd1Section(childIds[2], id, broker);
		this.theme = new ThemeExperience(childIds[3], id, broker);
	}
}
