// Code generated by mtribes. DO NOT EDIT.

/* tslint:disable */
import { Experience, Broker } from '@mtribes/client-browser';

export interface ListExperienceData {
	title?: string;
	listId?: string;
	imageType?: string;
}

export default class ListExperience extends Experience<ListExperienceData> {
	/**
	 * The kind of Experience in string form.
	 */
	readonly kind = 'ListExperience';

	constructor(id: string, parentId: string, broker: Broker) {
		super(id, parentId, broker, {
			id: '4rx7bRm',
			t: 'e',
			dataAlias: {
				'1': 'title',
				'3': 'listId',
				'2': 'imageType',
			},
		});
	}
}
