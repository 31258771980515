// Code generated by mtribes. DO NOT EDIT.

/* tslint:disable */
import { Collection, Broker } from '@mtribes/client-browser';

import BannerExperience from '../experience/bannerExperience';
import HeroExperience from '../experience/heroExperience';
import Hcd3Section from '../section/hcd3Section';
import ThemeExperience from '../experience/themeExperience';

export default class Hcd3Collection extends Collection {
	
	readonly hero: HeroExperience;
	readonly directmessage: BannerExperience;
	readonly section: Hcd3Section;
	readonly theme: ThemeExperience;

	constructor(id: string, childIds: string[], broker: Broker) {
		super(id, broker, {
			id: 'mB69g7o',
			t: 'c'
		});
		this.hero = new HeroExperience(childIds[0], id, broker);
		this.directmessage = new BannerExperience(childIds[1], id, broker);
		this.section = new Hcd3Section(childIds[2], id, broker);
		this.theme = new ThemeExperience(childIds[3], id, broker);
	}
}
