import React from 'react';
import Row, { ExperienceRowTypes } from './Row';

interface Rows {
	experiences: ExperienceRowTypes[];
}

const Rows: React.FC<Rows> = ({ experiences }) => {
	return (
		<>
			{experiences.map((e, i) => (
				<Row key={`row:${e.kind}:${i}`} experience={e} />
			))}
		</>
	);
};

export default Rows;
