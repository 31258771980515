import React from 'react';

const Link: React.FC<React.HTMLProps<HTMLAnchorElement>> = ({ children, href, ...props }) => {
	return (
		<a href={href || window.location.pathname} onClick={href ? undefined : e => e.preventDefault()} {...props}>
			{children}
		</a>
	);
};

export default Link;
