import React from 'react';
import styles from './List.module.css';
import useList from '../../../hook/useList';
import cx from 'classnames';
import ListItem from './ListItem';
import Carousel from './Carousel';

interface Props {
	className?: string;
	title?: string;
	listId: string;
	imageType?: api.ImageType;
}

const List: React.FC<Props> = ({ className, title, listId, imageType }) => {
	const list = useList(listId);
	if (!listId) return null;
	return (
		<section className={cx(styles.root, className)}>
			{!!title && (
				<h3 className={cx(styles.title, !list && [styles.placeholderTitle, 'block-loader'])}>{list ? title : '...'}</h3>
			)}
			{!!list && (
				<Carousel>
					{list.items.map(item => (
						<ListItem key={item.id} className={styles.item} item={item} imageType={imageType} />
					))}
				</Carousel>
			)}
		</section>
	);
};

export default List;
