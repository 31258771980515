// Code generated by mtribes. DO NOT EDIT.

/* tslint:disable */
import { Collection, Broker } from '@mtribes/client-browser';

import BannerExperience from '../experience/bannerExperience';
import HeroExperience from '../experience/heroExperience';
import Hcd2Section from '../section/hcd2Section';
import ThemeExperience from '../experience/themeExperience';

export default class Hcd2Collection extends Collection {
	
	readonly hero: HeroExperience;
	readonly directmessage: BannerExperience;
	readonly section: Hcd2Section;
	readonly theme: ThemeExperience;

	constructor(id: string, childIds: string[], broker: Broker) {
		super(id, broker, {
			id: 'D1D9RBq',
			t: 'c'
		});
		this.hero = new HeroExperience(childIds[0], id, broker);
		this.directmessage = new BannerExperience(childIds[1], id, broker);
		this.section = new Hcd2Section(childIds[2], id, broker);
		this.theme = new ThemeExperience(childIds[3], id, broker);
	}
}
