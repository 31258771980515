// Code generated by mtribes. DO NOT EDIT.

/* tslint:disable */
import { Fallbacks } from '@mtribes/client-browser';

export const fallbacks: Fallbacks = {
	v: 20505,
	json:
		'{"2jBZkKl":{"on":true,"sid":"3xLX8Lj","v":41430,"data":"{\\"1\\":\\"Action\\",\\"2\\":\\"2925\\",\\"3\\":\\"poster\\"}"},"2qB5XVQ":{"on":true,"sid":"y7aP6ZW","v":75876,"data":"{\\"1\\":\\"spiderman\\",\\"2\\":false}","pid":"lDBJLW3"},"9rW97B3":{"on":false,"sid":"A8qdw7k","v":75391,"data":"{\\"1\\":\\"None\\",\\"2\\":\\"\\",\\"4\\":\\"right\\",\\"6\\":\\"light\\"}"},"E1BamBy":{"on":false,"sid":"Xa0n9kg","v":20589,"data":"{}"},"ENV69WM":{"on":true,"sid":"9KgyLXk","v":43227,"data":"{\\"1\\":\\"Action\\",\\"2\\":\\"12927\\",\\"3\\":\\"poster\\"}","pid":"lDBJLW3"},"GkWNRWj":{"on":true,"sid":"ngJoBVb","v":26138,"data":"{\\"1\\":\\"Drama\\",\\"2\\":\\"13393\\",\\"3\\":\\"poster\\"}","pid":"lDBJLW3"},"GlBDRWr":{"on":true,"sid":"VqQGq0Z","v":26149,"data":"{\\"1\\":\\"Hi\\",\\"2\\":\\"downloads promo\\",\\"3\\":\\"\\",\\"4\\":\\"Download\\",\\"5\\":\\"Get it!\\",\\"6\\":\\"left\\",\\"7\\":false,\\"8\\":\\"light\\"}","pid":"lDBJLW3"},"XZVoJW9":{"on":true,"sid":"9qnojbv","v":41984,"data":"{\\"1\\":\\"2930\\",\\"2\\":false}"},"Y4VRvVr":{"on":true,"sid":"70PpmbB","v":14803,"data":"{\\"1\\":\\"Drama\\",\\"2\\":\\"2927\\",\\"3\\":\\"poster\\"}"},"gxV35Kp":{"on":true,"sid":"gYyKLpw","v":24227,"data":"{\\"1\\":\\"12949\\",\\"2\\":false}"},"lDBJLW3":{"on":true,"sid":"vAky4XO","v":19010,"se":[{"id":"ENV69WM","et":"PV7jxdL"},{"id":"2qB5XVQ","et":"DX6Gg80"},{"id":"GlBDRWr","et":"D8qWkRv"},{"id":"GkWNRWj","et":"PV7jxdL"}]}}',
};
