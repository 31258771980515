import React from 'react';
import styles from './HeroTiles.module.css';
import HeroTile from './HeroTile';
import Carousel from '../list/Carousel';

interface Props {
	items?: api.Item[];
	selectedIndex: number;
	onSelect: (selectedIndex: number) => void;
}

const HeroTiles: React.FC<Props> = ({ items, selectedIndex, onSelect }) => {
	if (!items) return null;
	return (
		<Carousel className={styles.root}>
			{items.map((item, i) => (
				<HeroTile
					key={item.id}
					className={styles.item}
					item={item}
					selected={i === selectedIndex}
					onClick={() => onSelect(i)}
				/>
			))}
		</Carousel>
	);
};

export default HeroTiles;
