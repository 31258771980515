// Code generated by mtribes. DO NOT EDIT.

/* tslint:disable */
import { Section, Broker } from '@mtribes/client-browser';

import ListExperience from '../experience/listExperience';
import InlineBannerAdExperience from '../experience/inlineBannerAdExperience';
import MediaRowExperience from '../experience/mediaRowExperience';

export class Hcd3SectionSupported {
	readonly List = 'ListExperience';
	readonly InlineBannerAd = 'InlineBannerAdExperience';
	readonly MediaRow = 'MediaRowExperience';
}

export type Hcd3SectionSupportedTypes = ListExperience | InlineBannerAdExperience | MediaRowExperience;

export default class Hcd3Section extends Section<Hcd3SectionSupported, Hcd3SectionSupportedTypes> {
	// Type alias's for easy switching of supported Section Experience types
	readonly supported = new Hcd3SectionSupported();

	constructor(id: string, parentId: string, broker: Broker) {
		super(
			id,
			parentId,
			broker,
			{
				id: 'Z9M3LWz',
				t: 's',
			},
			{
				'4rx7bRm': ListExperience,
				bM5DaX1: InlineBannerAdExperience,
				XoWy1WM: MediaRowExperience,
			}
		);
	}
}
