import React from 'react';
import { useExperience } from '@mtribes/client-react';
import Rows from '../component/row/Rows';

interface Props {
	collection: any;
}

const HomeSimple: React.FC<Props> = ({ collection }) => {
	const experiences = [
		useExperience(collection.hero),
		useExperience(collection.list1),
		useExperience(collection.list2)
	];
	return <Rows experiences={experiences} />;
};

export default HomeSimple;
