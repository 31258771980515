import React, { useEffect, useRef } from 'react';
import styles from './HeroTile.module.css';
import cx from 'classnames';
import ListItem from '../list/ListItem';

interface Props {
	className: string;
	item: api.Item;
	selected: boolean;
	onClick: () => void;
}

const HeroTile: React.FC<Props> = ({ className, item, selected, onClick }) => {
	const ref = useRef<HTMLButtonElement>(null);
	const prevSelected = useRef(selected);
	useEffect(() => {
		if (!prevSelected.current && selected && ref && ref.current) {
			ref.current.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
		}
		prevSelected.current = selected;
	}, [selected]);
	return (
		<ListItem
			innerRef={ref}
			className={cx({ [styles.selected]: selected }, className)}
			item={item}
			imageType="tile"
			onClick={onClick}
		/>
	);
};

export default HeroTile;
