// Code generated by mtribes. DO NOT EDIT.

/* tslint:disable */
import { Experience, Broker, ColorProperty } from '@mtribes/client-browser';

export interface ThemeExperienceData {
	theme?: string;
	headerColour?: ColorProperty;
	secondaryColor?: ColorProperty;
}

export default class ThemeExperience extends Experience<ThemeExperienceData> {
	/**
	 * The kind of Experience in string form.
	 */
	readonly kind = 'ThemeExperience';

	constructor(id: string, parentId: string, broker: Broker) {
		super(id, parentId, broker, {
			id: 'Lvl4Kqx',
			t: 'e',
			dataAlias: {
				'1': 'theme',
				'3': 'headerColour',
				'4': 'secondaryColor',
			},
		});
	}
}
