import { useEffect, useState, useMemo } from 'react';
import { getList } from '../util/api';
import { getCachedList, setCachedList } from '../util/cache';

export default function useList(listId: string): api.List | undefined {
	const [list, setList] = useState<api.List>();
	const cachedList = useMemo(() => getCachedList(listId), [listId]);

	useEffect(() => {
		// Skip if we have the list from cache, or the list is loaded in state
		if (cachedList || (list && list.id === listId)) return;

		// Fetch the list from api
		let cancelled = false;
		getList(listId).then(list => {
			if (list) setCachedList(listId, list);
			if (!cancelled) setList(list);
		});
		return () => {
			cancelled = true;
		};
	}, [cachedList, list, listId]);

	return list || cachedList;
}
