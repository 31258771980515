// Code generated by mtribes. DO NOT EDIT.

/* tslint:disable */
import { Experience, Broker } from '@mtribes/client-browser';

export interface ThemeExperienceData {
	theme?: string;
}

export default class ThemeExperience extends Experience<ThemeExperienceData> {
	/**
	 * The kind of Experience in string form.
	 */
	readonly kind = 'ThemeExperience';

	constructor(id: string, parentId: string, broker: Broker) {
		super(id, parentId, broker, {
			id: 'GMV5zpV',
			t: 'e',
			dataAlias: {
				'1': 'theme',
			},
		});
	}
}
