// Code generated by mtribes. DO NOT EDIT.

/* tslint:disable */
import { Collection, Broker } from '@mtribes/client-browser';

import RecsExperience from '../experience/recsExperience';
import PersonalizationSection from '../section/personalizationSection';

export default class PersonalizationCollection extends Collection {
	readonly recs: RecsExperience;
	readonly recs1: RecsExperience;
	readonly section: PersonalizationSection;

	constructor(id: string, childIds: string[], broker: Broker) {
		super(id, broker, {
			id: 'r3WMW9B',
			t: 'c',
		});
		this.recs = new RecsExperience(childIds[0], id, broker);
		this.recs1 = new RecsExperience(childIds[1], id, broker);
		this.section = new PersonalizationSection(childIds[2], id, broker);
	}
}
