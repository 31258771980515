import React, { useMemo } from 'react';
import { buildSources } from '../util/image';

interface Props {
	className?: string;
	alt: string;
	source: string;
	sizes: image.Size[];
}

const Picture: React.FC<Props> = ({ className, alt, source, sizes }) => {
	const sources = useMemo(() => buildSources(source, sizes), [source, sizes]);
	return (
		<picture>
			{sources.map(({ url, media }) => (
				<source key={url} srcSet={url} media={media} />
			))}
			<img className={className} alt={alt} src={sources[0].url} />
		</picture>
	);
};

export default Picture;
