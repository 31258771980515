import React, { useState } from 'react';
import styles from './Banner.module.css';
import cx from 'classnames';
import { ReactComponent as DismissIcon } from './dismiss.svg';
import promoContent from './promo-content.png';
import promoDownloads from './promo-downloads.png';
import promoGot from './promo-got.png';
import welcomeGot from './welcome-got.png';
import welcomeRegister from './welcome-register.png';
import welcome from './welcome.png';

export type BannerTheme = 'dark' | 'light';

const backgroundAssetMap: any = {
	welcome: welcome,
	'registration success': welcomeRegister,
	'GoT welcome': welcomeGot,
	'downloads promo': promoDownloads,
	'content promo': promoContent,
	'GoT promo': promoGot
};
const fitImages: any = { [promoContent]: true, [promoDownloads]: true, [promoGot]: true, [welcomeGot]: true };
const gotImages: any = { [promoGot]: true, [welcomeGot]: true };

interface Props {
	className?: string;
	title?: string;
	message?: string;
	action?: string;
	actionLocation?: 'left' | 'right';
	dismissible?: boolean;
	theme?: BannerTheme;
	background?: string;
}

const Banner: React.FC<Props> = ({
	className,
	title,
	message,
	action,
	actionLocation = 'right',
	dismissible,
	theme = 'light',
	background
}) => {
	const [dismissed, setDismissed] = useState(false);
	if (dismissed) return null;
	const actionModifier = actionLocation === 'left' ? styles.actionLeft : styles.actionRight;

	const src = background && backgroundAssetMap[background];
	const fit = background && fitImages[src];
	const got = background && gotImages[src];
	const style = { backgroundImage: src ? `url("${src}")` : undefined };
	return (
		<section
			className={cx(styles.root, styles[theme], { [styles.got]: got, [styles.backgroundFit]: fit }, className)}
			style={style}>
			<div className={styles.content}>
				{!!title && <h3 className={styles.title}>{title}</h3>}
				{!!message && <p className={styles.message}>{message}</p>}
				{!!action && <button className={cx(styles.actionButton, actionModifier)}>{action}</button>}
				{dismissible && (
					<button className={styles.dismissButton} onClick={() => setDismissed(true)}>
						<DismissIcon />
					</button>
				)}
			</div>
		</section>
	);
};

export default Banner;
