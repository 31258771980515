import React from 'react';
import Hero from './hero/Hero';
import List from './list/List';
import Banner, { BannerTheme } from './banner/Banner';
import MediaRowFixed from '../../mtspace/demo_app/experience/mediaRowExperience';
import BannerFixed from '../../mtspace/demo_app/experience/bannerExperience';
import InlineBannerAdFixed from '../../mtspace/demo_app/experience/inlineBannerAdExperience';
import HeroFixed from '../../mtspace/demo_app/experience/heroExperience';
import ListFixed from '../../mtspace/demo_app/experience/listExperience';
import styles from './Row.module.css';
import Feature from './feature/Feature';

export type ExperienceRowTypes = HeroFixed | ListFixed | BannerFixed | MediaRowFixed | InlineBannerAdFixed;

interface Props {
	experience: ExperienceRowTypes;
}

const Row: React.FC<Props> = ({ experience }) => {
	if (!experience.enabled) return null;
	switch (experience.kind) {
		case 'HeroExperience':
			return <Hero className={styles.rowMargin} listId={experience.data.listId!} />;

		case 'ListExperience':
			const { title: listTitle, listId, imageType } = experience.data;
			return (
				<List className={styles.rowMargin} title={listTitle} listId={listId!} imageType={imageType as api.ImageType} />
			);

		case 'BannerExperience':
		case 'InlineBannerAdExperience':
			const {
				title: bannerTitle,
				message,
				action,
				actionLocation,
				theme,
				dismissable,
				backgroundImage,
			} = experience.data;
			return (
				<Banner
					className={styles.rowMargin}
					title={bannerTitle}
					message={message}
					action={action}
					actionLocation={actionLocation as any}
					theme={theme as BannerTheme}
					dismissible={dismissable}
					background={backgroundImage}
				/>
			);

		case 'MediaRowExperience':
			const { contentId } = experience.data;
			return <Feature type={contentId === 'got' ? 'got' : 'marvel'} className={styles.rowMargin} />;
	}
	return null;
};

export default Row;
