// Code generated by mtribes. DO NOT EDIT.

/* tslint:disable */
import {
	Client,
	Broker,
	Signal as SignalAlias,
	SessionEvent as SessionEventAlias,
	ChangeEvent as ChangeEventAlias,
	ColorProperty as ColorPropertyAlias,
} from '@mtribes/client-browser';
import { fallbacks } from './fallbacks';
import Hcd1Collection from './collection/hcd1Collection';
import Hsd1Collection from './collection/hsd1Collection';

export { Client, Session, SessionStatus, ChangeEventType, Experience, Section } from '@mtribes/client-browser';

export type Signal<SessionEvent> = SignalAlias<SessionEvent>;
export type SessionEvent = SessionEventAlias;
export type ChangeEvent = ChangeEventAlias;
export type ColorProperty = ColorPropertyAlias;

const broker = new Broker();
export const client = new Client('0e9e06c165c84c41b01b952e498e5059', fallbacks, broker);
export const session = client.session;

export const collections: Collections = {
	hcd1: new Hcd1Collection('a2KjXV4', ['9rW97B3', 'gxV35Kp', 'lDBJLW3', 'E1BamBy'], broker),
	hsd1: new Hsd1Collection('9XK2XVn', ['XZVoJW9', 'Y4VRvVr', '2jBZkKl'], broker),
};

export interface Collections {
	hcd1: Hcd1Collection;
	hsd1: Hsd1Collection;
}
