import React, { useMemo } from 'react';
import styles from './ListItem.module.css';
import cx from 'classnames';
import { setImageSize, IMAGE_WIDTH } from '../../../util/image';

interface Props {
	className?: string;
	item: api.Item;
	imageType?: api.ImageType;
	innerRef?: React.Ref<HTMLButtonElement>;
	onClick?: () => void;
}

const ListItem: React.FC<Props> = ({ className, item, imageType = 'poster', innerRef, onClick }) => {
	const url = useMemo(() => setImageSize(item.images[imageType], IMAGE_WIDTH[imageType]), [item, imageType]);
	return (
		<button ref={innerRef} className={cx(styles.root, `image-${imageType}`, className)} onClick={onClick}>
			<img className={styles.image} alt={item.title} src={url} />
		</button>
	);
};

export default ListItem;
