import React from 'react';
import { ReactComponent as ArrowIcon } from './arrow.svg';
import styles from './ArrowButton.module.css';
import cx from 'classnames';

interface Props {
	className?: string;
	onClick?: () => void;
	direction?: 'left' | 'right';
}

const ArrowButton: React.FC<Props> = ({ className, onClick, direction = 'left' }) => {
	return (
		<button className={className} onClick={onClick}>
			<ArrowIcon className={cx(styles.icon, direction === 'right' && styles.invert)} />
		</button>
	);
};

export default ArrowButton;
