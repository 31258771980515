import React from 'react';
import gotBackground from './got/background.png';
import gotCharacter from './got/character.png';
import gotLogo from './got/logo.png';
import gotThumbs from './got/thumbs.png';
import marvelBackground from './marvel/background.png';
import marvelCharacter from './marvel/character.png';
import marvelLogo from './marvel/logo.png';
import marvelThumbs from './marvel/thumbs.png';
import styles from './Feature.module.css';
import { ReactComponent as HeartIcon } from './heart.svg';
import cx from 'classnames';

const CONTENT = {
	got: {
		title: 'Game of Thrones:\nWinter is here',
		tagline: 'The Real War Is Between The Living And The Dead',
		description:
			'Nine noble families fight for control over the mythical lands of Westeros, while an ancient enemy returns after being dormant for thousands of years.',
		images: [gotBackground, gotCharacter, gotLogo, gotThumbs]
	},
	marvel: {
		title: 'Spiderman:\nHomecoming',
		tagline: 'Experience the Marvel Cinematic Universe.',
		description:
			'Peter Parker balances his life as an ordinary high school student in Queens with his superhero alter-ego Spider-Man, and finds himself on the trail of a new menace prowling the skies of New York City.',
		images: [marvelBackground, marvelCharacter, marvelLogo, marvelThumbs]
	}
};

interface Props {
	className?: string;
	type?: 'marvel' | 'got';
}

const Feature: React.FC<Props> = ({ className, type = 'marvel' }) => {
	const { title, tagline, description, images } = CONTENT[type];
	const [background, character, logo, thumbs] = images;
	return (
		<section className={cx(styles.root, styles[type], className)}>
			<img alt="background" src={background} className={styles.background} />
			<img alt="character" src={character} className={styles.character} />
			<div className={styles.overlay} />
			<div className={styles.left}>
				<img alt="logo" src={logo} className={styles.logo} />
				<p className={styles.tagline}>{tagline}</p>
				<img alt="thumbnails" src={thumbs} className={styles.thumbs} />
			</div>
			<div className={styles.right}>
				<h3 className={styles.title}>
					{title.split('\n').reduce(
						(acc, v, i) => {
							if (i > 0) acc.push(<br key={`br-${i}`} />);
							acc.push(<span key={`span-${i}`}>{v}</span>);
							return acc;
						},
						[] as React.ReactNode[]
					)}
				</h3>
				<p className={styles.description}>{description}</p>
				<div className={styles.buttons}>
					<button className={styles.watchButton}>watch trailer</button>
					<button className={styles.saveButton}>
						<HeartIcon className={styles.saveButtonIcon} />
						save for later
					</button>
				</div>
			</div>
		</section>
	);
};

export default Feature;
