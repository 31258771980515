import React from 'react';
import styles from './Header.module.css';
import HeaderLink from './HeaderLink';
import { ReactComponent as ProfileIcon } from './profile.svg';
import cx from 'classnames';

interface Props {
	onSignIn: () => void;
	onSignUp: () => void;
	onSignOut: () => void;
	loggedIn: boolean;
}

const Header: React.FC<Props> = ({ onSignIn, onSignUp, onSignOut, loggedIn }) => {
	return (
		<header className={styles.root}>
			<div className={styles.left}>
				<HeaderLink>Home</HeaderLink>
				<HeaderLink>Movies</HeaderLink>
				<HeaderLink>TV</HeaderLink>
				<HeaderLink>Live</HeaderLink>
			</div>
			{loggedIn ? (
				<button className={cx([styles.button, styles.profileButton])} onClick={onSignOut}>
					<ProfileIcon />
					<span className={styles.profileButtonLabel}>Sign out</span>
				</button>
			) : (
				<>
					<button className={styles.button} onClick={onSignIn}>
						Sign in
					</button>
					<button className={cx([styles.button, styles.signUpButton])} onClick={onSignUp}>
						Sign up
					</button>
				</>
			)}
		</header>
	);
};

export default Header;
