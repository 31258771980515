// Code generated by mtribes. DO NOT EDIT.

/* tslint:disable */
import { Collection, Broker } from '@mtribes/client-browser';

import HeroExperience from '../experience/heroExperience';
import ListExperience from '../experience/listExperience';

export default class Hsd1Collection extends Collection {
	
	readonly hero: HeroExperience;
	readonly list1: ListExperience;
	readonly list2: ListExperience;

	constructor(id: string, childIds: string[], broker: Broker) {
		super(id, broker, {
			id: 'bG6aNQO',
			t: 'c'
		});
		this.hero = new HeroExperience(childIds[0], id, broker);
		this.list1 = new ListExperience(childIds[1], id, broker);
		this.list2 = new ListExperience(childIds[2], id, broker);
	}
}
