import React from 'react';
import styles from './NotFound.module.css';
import { collections } from '../mtspace/demo_app';

const NotFound: React.FC = () => {
	return (
		<div className={styles.container}>
			<h1>Page not found</h1>
			<p>Try one of these collections:</p>
			<ul>
				{Object.keys(collections).map(name => (
					<li>
						<a href={`/${name}`}>{name}</a>
					</li>
				))}
			</ul>
		</div>
	);
};

export default NotFound;
